import React from "react";
import { CardContent, CardHeader, Avatar, Divider } from "@mui/material";
import Card from "@mui/material/Card";
import MyAvatar from "./MyAvatar";

interface PostCardProps {
  children: React.ReactNode;
  date: React.ReactNode;
  title: React.ReactNode;
  className?: string;
}

const PostCard: React.FC<PostCardProps> = ({
  children,
  date,
  title,
  className,
}) => {
  return (
    <Card className={className}>
      <CardHeader title={title} subheader={date} avatar={<MyAvatar />} />
      <Divider />
      <CardContent>{children}</CardContent>
    </Card>
  );
};

export default PostCard;
