import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import PostCard from "../components/post-card";
import MdxLayout from "../components/mdx-layout";

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;

const BlogPostTemplate: React.FC = ({ data }: any) => {
  const { mdx: post } = data;

  return (
    <Layout pageTitle={post.frontmatter.title}>
      <PostCard title={post.frontmatter?.title} date={post.frontmatter?.date}>
        <MdxLayout>
          <MDXRenderer>{post.body}</MDXRenderer>
        </MdxLayout>
      </PostCard>
    </Layout>
  );
};

export default BlogPostTemplate;
